import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet amb un capell de 2 a 5 cm de diàmetre, hemisfèric o pla convex, de color groc cremós a ocraci, més fosc al centre i cobert de grans farinosos. Del disc central al marge presenta una mena de plecs arrugats. Les làmines de davall són blanques, desiguals, d’adherents a escotades, amb nombroses lamel·les. El peu és cilíndric engruixut a la base i a partir d’un cm per davall del capell presenta esquames o granulositats repartides que li donen un aspecte característic. A més presenta un anell farinós, poc aparent, de color semblant al capell. Les espores són blanquinoses en massa, de forma el·líptica, de 5,5-6 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      